

$(document).on('turbo:load', () => {
    $('table.irreg-steles').on("click", ".preview_link", function (event) {
        var url = $(this).attr('href');

        window.open(url, "preview", "toolbar=no,scrollbars=no,resizable=no,top=500,left=500,width=302,height=590");

        event.preventDefault();
    });

    function use_title(obj) {
        return obj['title'].split(/[\s_]+/);
    }

    var templates = new Bloodhound({
        datumTokenizer: use_title,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        identify: function (obj) {
            return obj.title;
        },
        local: []
    });

    function templatesWithDefault(q, sync) {
        if (q === '') {
            sync(templates.all());
        } else {
            templates.search(q, sync);
        }
    }

    templates.add([
        {
            title: 'Hotelvoucher  (de./engl.) (4 Screens)',
            headline1: 'Hotelvoucher',
            message1: 'Sehr geehrte Fluggäste, wenn Sie bereits auf einen Flug für morgen umgebucht wurden, bitten wir Sie am Schalter XXX Ihren Hotelvoucher abzuholen.',
            headline2: 'Hotelvoucher',
            message2: 'Leider finden heute keine Lufthansa Abflüge mehr statt. Bitte wenden Sie sich zur Ausstellung eines Hotelgutscheines an Schalter XXX',
            headline3: 'Hotelvoucher',
            message3: 'Dear passengers, Lufthansa passengers who are rebooked for a flight tomorrow are requested to pick up their hotel vouchers at counter number XXX.',
            headline4: 'Hotelvoucher',
            message4: 'Unfortunately there are no more Lufthansa flights operating today. Please contact counter XXX for your hotel voucher.'
        },
        {
            title: 'Innerdeutsche Flugannulierung (de./engl.) (2 Screens)',
            headline1: 'Innerdeutsche Flugannulierung',
            message1: 'An alle innerdeutschen Fluggäste: Sie erhalten einen Reisegutschein zur Weiterreise in Nah- und Fernzügen der Deutschen Bahn am Check-In Kiosk oder rufen diesen online in der LH App oder auf lufthansa.com ab.  Bitte holen Sie  Ihr Gepäck vor Fortsetzung Ihrer Reise in der Gepäckausgabe ab.',
            headline2: 'Inner german cancellation',
            message2: 'Information for all passengers travelling domestic only. You will receive a travel voucher für "Deutsche Bahn" (railway) at the check-in machine, online in the Lufthansa app or contact our Lufthansa staff at the train counter 469-474. This voucher is valid immediately for travelling on „Deutsche Bahn“ trains to your final destination. Please collect your luggage prior to continue your journey in the baggage claim area.'
        },
        {
            title: 'Annulierung eines Europa- oder Langstreckenfluges (de./engl.) (2 Screens)',
            headline1: 'Annulierung eines Europa- oder Langstreckenfluges',
            message1: 'Sehr geehrte Fluggäste, bitte überprüfen Sie ob Sie über die LH App oder am Check-in Automat auf einen Alternativflug umgebucht wurden.\n' +
                'Die Bordkarte können Sie direkt am Check-in Automat ausdrucken oder online in der LH App abrufen.',
            headline2: 'Cancellation of an continental \n' +
                'or long-haul flight',
            message2: 'Dear passengers, please check at the next check-in machine if there is an alternative flight available and print your new boarding pass there.  \n' +
                'Your luggage will automatically be rerouted to your new flight.'
        },
        {
            title: 'Witterungsbedingte innerdeutsche Umbuchung auf Bahn (de./engl.) (2 Screens)',
            headline1: 'Witterungsbedingte innerdeutsche Umbuchung auf Bahn',
            message1: 'Sehr geehrte Fluggäste, aufgrund der Wetterverhältnisse bitten wir alle Passagiere, gebucht auf innerdeutschen Flügen, die Deutsche Bahn zu nutzen. Am Lufthansa Check-in Automat oder online über die Lufthansa App können Sie sich Ihren Bahngutschein ausdrucken.',
            headline2: 'Train rebooking due to weather conditions for domestic flights',
            message2: 'Dear passengers, due to the bad weather situation we ask all passengers travelling within Germany only to use the train. You will receive your train voucher at the check-in machine, mobile in our Lufthansa app or contact our Lufthansa staff at the  counter XXX. '
        },
        {
            title: 'Streikbedingte innerdeutsche Umbuchung auf die Bahn (de./engl.) (2 Screens)',
            headline1: 'Streikbedingte innerdeutsche Umbuchung auf die Bahn',
            message1: 'Sehr geehrte Fluggäste, aufgrund des Streiks bitten wir alle Passagiere, gebucht auf innerdeutschen Flügen, die Deutsche Bahn zu nutzen. Am Lufthansa Check-in Automat oder online über die Lufthansa app können Sie sich Ihren Bahngutschein ausdrucken.',
            headline2: 'Train rebooking for domestic flights due to strike',
            message2: 'Dear passengers, due to strike we ask all passengers travelling within Germany to use the train. You will receive your train voucher at the check-in machine, mobile in our Lufthansa app or contact our Lufthansa staff at the  counter XXX.'
        },
        {
            title: 'Verspätungen und Annullierungen aufgrund der Wetterlage (de./engl.) (2 Screens)',
            headline1: 'Verspätungen und Annullierungen aufgrund der Wetterlage',
            message1: 'Sehr geehrte Fluggäste, aufgrund der schlechten Wetterverhältnisse sind viele Flüge verspätet. Bitte beachten Sie die Informationen auf den Anzeigetafeln. Falls Ihr Flug annulliert ist, wenden Sie sich bitte an unsere Servicecenter oder schauen in der Lufthansa App, ob Sie schon umgebucht wurden.',
            headline2: 'Delays and cancellations due to the weather situation',
            message2: 'Dear passengers, we regret that your flight is delayed due to bad weather. Please check your flight status on the airport screens. If your flight is cancelled, please proceed to the ticket counter or check out Lufthansa app, if you are rebooked already.'
        },
        {
            title: 'Verspätungen und  Annullierungen  aufgrund von Streik (de./engl.) (2 Screens)',
            headline1: 'Verspätungen und  Annullierungen  aufgrund von Streik',
            message1: 'Sehr geehrte Fluggäste, aufgrund von Streik sind viele Flüge verspätet. Bitte beachten Sie die Informationen auf den Anzeigetafeln. Falls Ihr Flug annulliert ist, wenden Sie sich bitte an unsere Servicecenter oder schauen in der Lufthansa App, ob Sie schon umgebucht wurden.',
            headline2: 'Delays and cancellations due to strike',
            message2: 'Dear passengers, we regret that your flight is delayed due to strike. Please check your flight status on the airport screens. If your flight is cancelled, please proceed to the ticket counter or check out Lufthansa app, if you are rebooked already.'
        },
        {
            title: 'Gewitter (de./engl.) (4 Screens)',
            headline1: 'Gewitter', message1: 'Sehr geehrte Fluggäste, aufgrund auftretender Gewitter kommt es heute zu Unregelmäßigkeiten im Luftverkehr. Bitte beachten Sie die Informationen auf den Anzeigetafeln. Falls Ihr Flug annulliert ist, wenden Sie sich bitte an unsere Servicecenter oder schauen in der Lufthansa App, ob Sie schon umgebucht wurden.',
            headline2: 'Gewitter', message2: 'Aufgrund des Wetters kommt es zu vermehrten Unregelmäßigkeiten im Flugbetrieb. \n' +
                '- Bahnen in Betrieb\n' +
                '- Bahnen tw. außer Betrieb\n' +
                '- Mit weiteren Annullierungen und längeren Verspätungen muss leider   gerechnet werden \n' +
                '- Gepäck wird ausgeschleust\n' +
                '- Gepäck wird umgeladen\n',
            headline3: 'Thunderstorm', message3: 'Dear passengers, due to thunderstorms irregularities are to be expected. Please check your flight status on the airport screens. If your flight is cancelled, please proceed to the ticket counter or check out Lufthansa app, if you are rebooked already.',
            headline4: 'Thunderstorm', message4: 'Due to bad weather irregularities occur. \n' +
                '- meanwhile all runways can be used for operation again\n' +
                '- runways had to be partly closed \n' +
                '- unfortunately there may be more cancellations and longer delays \n' +
                '- baggage will be delivered to baggage claim\n' +
                '- baggage will be automatically transferred \n' +
                '\n'
        },
        {
            title: 'Schalterschließung (de. / engl.) (2 Screens)',
            headline1: 'Schalterschließung',
            message1: 'Sehr geehrte Fluggäste, aufgrund gesetzlicher Vorgaben sind unsere Schalter  zwischen 00:00 und 05:00 Uhr geschlossen. Bis zur Schalterschließung erhalten Sie Gutscheine für eine Hotelübernachtung.',
            headline2: 'Closure of counter',
            message2: 'Dear passengers, due to German law regulations our Lufthansa counters are closed from 00:00 am through 05:00am. Until then you will receive vouchers for hotel accommodation. '
        },
        {
            title: 'Schalterschließung und Feldbettenangebot (de. /engl.) (2 Screens)',
            headline1: 'Schalterschließung und Feldbettenangebot',
            message1: 'Sehr geehrte Fluggäste, aufgrund gesetzlicher Bestimmungen sind unsere Schalter zwischen 00:00 und 05:00 Uhr geschlossen. Da die Hotels im Umkreis von München bereits ausgebucht sind, bieten wir Ihnen Übernachtungsmöglichkeiten im Flughafen Terminal an.',
            headline2: 'Closure of counter and provision of camp beds',
            message2: 'Dear passengers, due to German law regulation our Lufthansa counters are closed from 00:00 am through 05:00am. Unfortunately hotel accommodations are not available any more. Therefore we offer overnight stay at the airport.'
        },
        {
            title: 'Getränke und Snacks (de. /engl.) (2 Screens)',
            headline1: 'Getränke und Snacks',
            message1: 'Sehr geehrte Fluggäste, im Bereich XXX am XXX halten wir Getränke, Snacks, Decken und Kissen für Sie bereit.',
            headline2: 'Soft drinks and snacks',
            message2: 'Soft drinks and snacks are available for you at counter/Gate XXX.'
        },
        {
            title: 'Nutzung der App (de. /engl.) (2 Screens)',
            headline1: 'Nutzung der App',
            message1: 'Informationen über Ihren aktuellen Flugstatus erhalten Sie über Ihr internetfähiges Mobilfunkgerät unter mobile.lufthansa.com> Meine Buchungen und über die Lufthansa App. ',
            headline2: 'Online Services',
            message2: 'Information about your current flight status is available via your internet-enabled mobile device at mobile.LH.com>My bookings and via the Lufhansa app.'
        }

        ]);

    $('#irreg_stele_headline1').typeahead({
            hint: false,
            highlight: false,
            minLength: 0
        },
        {
            name: 'templates',
            limit: 20,
            source: templatesWithDefault,
            display: 'headline1',
            templates: {
                suggestion: function (param1) {
                    return $("<div>").text(param1.title);
                }
            }
        }
        ).on('typeahead:select', function (event, value) {
            $('#irreg_stele_message1').val(value.message1);
            $('#irreg_stele_headline2').val(value.headline2);
            $('#irreg_stele_message2').val(value.message2);
            if (value.headline3) {
                $('#irreg_stele_headline3').val(value.headline3);
            }
            if (value.message3) {
                $('#irreg_stele_message3').val(value.message3);
            }
            if (value.headline4) {
                $('#irreg_stele_headline4').val(value.headline4);
            }
            if (value.message4) {
                $('#irreg_stele_message4').val(value.message4);
            }

        });
});
