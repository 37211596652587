import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["button"];

  declare readonly hasButtonTarget: boolean;
  declare readonly buttonTarget: HTMLSelectElement;
  declare readonly buttonTargets: HTMLSelectElement[];

  connect(): void {}

  click(event: Event): void {
    const target = event.target as HTMLElement;
    if (target.dataset.active == "true") {
      target.dataset.active = "false";
      target.className = this.button_classes(false);
    } else {
      target.dataset.active = "true";
      target.className = this.button_classes(true);
    }
    const activeButtons = this.buttonTargets
      .filter((button) => button.dataset.active == "true")
      .map((button) => button.dataset.value);
    console.log(activeButtons);
    event.preventDefault();

    const frameUrl =
      document.getElementById("results-table")?.getAttribute("src") ||
      location.href;

    let url = new URL(frameUrl);
    url.searchParams.set("tags", activeButtons.join(","));

    Turbo.visit(url, {
      action: "replace",
      frame: "results-table",
    });
  }

  button_classes(active: boolean): string {
    if (active) {
      return "btn btn-outline-primary active";
    } else {
      return "btn btn-primary";
    }
  }
}
