import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller<HTMLFormElement> {
  declare readonly buttonTargets: HTMLSelectElement[];

  submit(event) {
    const frameUrl =
      document.getElementById("results-table")?.getAttribute("src") ||
      location.href;

    let url = new URL(frameUrl);
    var formData = new FormData(this.element);

    // iterate through entries...
    for (var pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
      const value = pair[1] as string;
      if (value !== null) {
        url.searchParams.set(pair[0], value);
      }
    }

    Turbo.visit(url, {
      action: "replace",
      frame: "results-table",
    });
  }
}
