import { Controller } from "@hotwired/stimulus";

import multi from "multi.js/dist/multi-es6.min.js";
import "multi.js/dist/multi.min.css";

export default class extends Controller {
  static targets = ["input"];

  declare readonly hasInputTarget: boolean;
  declare readonly inputTarget: HTMLSelectElement;
  declare readonly inputTargets: HTMLSelectElement[];

  connect(): void {
    multi(this.inputTarget, {
      enable_search: true,
      search_placeholder: "Suchen...",
      non_selected_header: "alle Benutzer",
      selected_header: "Berechtige Benutzer",
      limit: -1,
      limit_reached: function () {},
      hide_empty_groups: true,
    });
  }
}
