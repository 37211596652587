import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["panel", "arrow", "button"];

  declare panelTarget: any;
  declare arrowTarget: any;
  declare buttonTarget: any;

  connect() {
    console.log("Accordion controller connected");
  }

  toggle_panel() {
    const panel = this.panelTarget;
    const isVisible = panel.style.display === "block";

    if (isVisible) {
      panel.style.display = "none";
      this.arrowTarget.innerHTML = "&#9654;";
    } else {
      panel.style.display = "block";
      this.arrowTarget.innerHTML = "&#9660;";
    }

    this.buttonTarget.classList.toggle("active");
  }
}
