import { Controller } from "@hotwired/stimulus";

import tagger from "@jcubic/tagger";
import "../stylesheets/tagger.scss";

export default class extends Controller {
  static targets = ["input"];

  declare readonly hasInputTarget: boolean;
  declare readonly inputTarget: HTMLSelectElement;
  declare readonly inputTargets: HTMLSelectElement[];

  connect(): void {
    console.log("Hello, Stimulus!", this.element);
    tagger(this.inputTarget, {
      allow_spaces: false,
      allow_duplicates: false,
      wrap: true,
    });
  }
}
