$(document).on("upload:start", ".edit_medium, .new_medium", function (e) {
  $(this).find("input[type=submit]").attr("disabled", true);
});

$(document).on("upload:complete", ".edit_medium, .new_medium", function (e) {
  if (!$(this).find("input.uploading").length) {
    $(this).find("input[type=submit]").removeAttr("disabled");
  }
  var url = JSON.parse(e.originalEvent.detail.xhr.response).url;
  $(this).find("#uploaded_file").attr("src", url);
});

$(document).on("input", "#media-search", function (e) {
  var needle = RegExp(".*" + $(this).val() + ".*", "i");
  $("#media-list")
    .children()
    .show()
    .filter(function () {
      var haystack = $(this).data("name");
      return haystack.search(needle);
    })
    .hide();
});

$(document).on(
  "change",
  ".media-checkbox, .local-content-checkbox, #local_content_check_all",
  function (e) {
    var mediaCount = $(".media-checkbox:checked").length;
    var localContentCount = $(".local-content-checkbox:checked").length;
    if (
      $(".media-checkbox").is(":checked") &&
      $(".local-content-checkbox").is(":checked")
    ) {
      $("#assign-media").prop("disabled", false);
      $("#assign-media").text(
        mediaCount + " Median zu " + localContentCount + " Stelen hinzufügen"
      );
    } else {
      $("#assign-media").prop("disabled", true);
      $("#assign-media").text("Assign Media");
    }
  }
);
