import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "image"];

  declare readonly hasInputTarget: boolean;
  declare readonly inputTarget: HTMLSelectElement;
  declare readonly inputTargets: HTMLSelectElement[];

  declare readonly hasImageTarget: boolean;
  declare readonly imageTarget: HTMLImageElement;
  declare readonly imageTargets: HTMLImageElement[];

  connect() {
    console.log("Hello, Stimulus!", this.element);
  }

  change(event: Event) {
    const newValue = this.inputTarget.value;
    if (newValue && newValue.length > 0) {
      this.imageTarget.src = attachments[newValue];
    } else {
      this.imageTarget.src = "";
    }
  }
}
