import { Controller } from "@hotwired/stimulus";
import toastr from "toastr";

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-center",
  preventDuplicates: false,
  onclick: undefined,
  showDuration: 200,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 1000,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "slideDown",
  hideMethod: "fadeOut",
};

export default class extends Controller {
  connect() {

    if (document.documentElement.hasAttribute("data-turbolinks-preview")) {
        return;
    }
    // Populate select boxes
    playlists
      .filter((p) => p.portrait)
      .forEach((p) => {
        $(`select[name="playlist"][data-orientation="portrait"]`).append(
          $("<option />").text(p.display_name).val(p.value)
        );
      });

    playlists
      .filter((p) => p.landscape)
      .forEach((p) => {
        $(`select[name="playlist"][data-orientation="landscape"]`).append(
          $("<option />").text(p.display_name).val(p.value)
        );
      });

    $(`select[name="duration"]`).append(
      $("<option />").text("Dauer wählen").val("")
    );
    [5, 10, 15, 20, 30, 45, 60].forEach((p) => {
      $(`select[name="duration"]`).append(
        $("<option />").text(`${p} Min.`).val(p)
      );
    });

    boxes.forEach((box) => {
      if (box.ip_address == null || box.ip_address.trim() === "") {
        return;
      }
      $.ajax({
        url: `http://${box.ip_address}:5003/api/Json/timeentries`,
        timeout: 200,
      })
        .done((data) => {
          const textfield = document.getElementById(box.box_id);
          if (textfield != null) {
            if (data.value.timeentries.length > 0) {
              textfield.value = JSON.stringify(data.value.timeentries[0]);
            } else {
              textfield.value = "";
            }
          }
          const timeentry = data.value.timeentries.find((p) => {
            return (
              new Date(p.start) < new Date() && new Date(p.end) > new Date()
            );
          });
          const playlist = timeentry?.playlist ?? "";
          set_playlist(playlist, box.box_id);
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          const textfield = document.getElementById(box.box_id);
          if (textfield != null) {
            if (jqXHR.status === 404) {
              textfield.value = "";
            } else {
              textfield.value = "Error: No connection to box";
            }
          }
          if (jqXHR.status != 404) {
            toastr["error"](
              `Fehler beim Verbinden zur Box ${box.box_id}`,
              "Fehler!"
            );
            $(`*[name="playlist"][data-box="${box.box_id}"]`).prop(
              "disabled",
              "disabled"
            );
            $(`*[name="duration"][data-box="${box.box_id}"]`).prop(
              "disabled",
              "disabled"
            );
            $(`.btn.save[data-box="${box.box_id}"]`).prop(
              "disabled",
              "disabled"
            );
            $(`img.itb_portrait[data-box="${box.box_id}"]`).prop(
              "src",
              placeholder_portrait
            );
            $(`img.itb_landscape[data-box="${box.box_id}"]`).prop(
              "src",
              placeholder_landscape
            );
          } else {
            set_playlist("", box.box_id);
          }
        });
    });

    $('select[name="playlist"]').on("change", function () {
      const box_id = this.dataset.box;
      const playlist = $(`*[name="playlist"][data-box="${box_id}"]`).val();
      const playlist_entry = playlists.find((p) => p.value === playlist);
      $(`img.itb_portrait[data-box="${box_id}"]`).prop(
        "src",
        playlist_entry.portrait_image
      );
      $(`img.itb_landscape[data-box="${box_id}"]`).prop(
        "src",
        playlist_entry.landscape_image
      );
      if (playlist === "") {
        $(`select[name="duration"][data-box="${box_id}"]`).removeClass(
          "is-invalid"
        );
      }
    });

    $(`select[name="duration"]`).on("change", function () {
      this.classList.remove("is-invalid");
    });

    $(".save").on("click", function () {
      const box_id = this.dataset.box;
      const playlist = $(`*[name="playlist"][data-box="${box_id}"]`).val();
      const duration = $(`*[name="duration"][data-box="${box_id}"]`).val();
      if (playlist != "" && (duration === "" || duration === "0")) {
        $(`select[name="duration"][data-box="${box_id}"]`).addClass(
          "is-invalid"
        );
      } else {
        send_box_info(box_id, playlist, parseInt(duration), () => {
          toastr["success"](
            "Die neue Playliste wurde gespeichert und wird demnächst anfangen abzuspielen.",
            "Gespeichert"
          );
        });
      }
    });
  }

  send(box_id) {
    const box = boxes.find((b) => b.box_id === box_id);
    const entries = document.getElementById(box_id)?.value;
    const data = {
      expirationType: "Absolute",
      expirationSeconds: 48000,
      value: {
        timeentries: [JSON.parse(entries)],
      },
    };
    $.ajax({
      url: `http://${box.ip_address}:5003/api/Json/timeentries`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      contentType: "application/json",
      data: JSON.stringify(data),
    });
  }

  send_box_info(box_id, playlist, duration, callback = null) {
    const box = boxes.find((b) => b.box_id === box_id);

    var timeentries = [];
    if (playlist !== "") {
      timeentries.push({
        start: new Date(),
        end: futureDate(duration),
        playlist: playlist,
      });
    }

    const data = {
      expirationType: "Absolute",
      expirationSeconds: 48000,
      value: {
        timeentries: timeentries,
      },
    };
    $.ajax({
      url: `http://${box.ip_address}:5003/api/Json/timeentries`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      contentType: "application/json",
      data: JSON.stringify(data),
    })
      .done((data) => {
        if (callback != null) {
          callback();
        }
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        alert(
          "Error: No connection to box: " + textStatus + " " + errorThrown + ""
        );
      });
  }

  futureDate(minutes) {
    return new Date(new Date().getTime() + minutes * 60000);
  }

  set_playlist(playlist, box_id) {
    const playlist_entry = playlists.find((p) => p.value === playlist);
    $(`*[name="playlist"][data-box="${box_id}"]`).val(playlist_entry.value);
    $(`img.itb_portrait[data-box="${box_id}"]`).prop(
      "src",
      playlist_entry.portrait_image
    );
    $(`img.itb_landscape[data-box="${box_id}"]`).prop(
      "src",
      playlist_entry.landscape_image
    );
  }
}
